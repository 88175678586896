<template>
  <b-card>

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col
          cols="3"
        >
          <b-form-group
            label="Primer Nombre"
            label-for="firstName"
          >
            <b-form-input
              v-model="item.firstName"
              name="firstName"
              :disabled="editable"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="3"
        >
          <b-form-group
            label="Segundo Nombre"
            label-for="firstName"
          >
            <b-form-input
              v-model="item.secondName"
              name="secondName"
              :disabled="editable"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="3"
        >
          <b-form-group
            label="Otros Nombres"
            label-for="otherNames"
          >
            <b-form-input
              v-model="item.otherNames"
              name="otherNames"
              :disabled="editable"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="3"
        >
          <b-form-group
            label="Primer Apellido"
            label-for="firstSurname"
          >
            <b-form-input
              v-model="item.firstSurname"
              name="firstSurname"
              :disabled="editable"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="3"
        >
          <b-form-group
            label="Segundo Apellido"
            label-for="secondSurname"
          >
            <b-form-input
              v-model="item.secondSurname"
              name="secondSurname"
              :disabled="editable"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="3"
        >
          <b-form-group
            label="Apellido de Casada"
            label-for="marriedSurname"
          >
            <b-form-input
              v-model="item.marriedSurname"
              name="marriedSurname"
              :disabled="editable"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="3"
        >
          <b-form-group
            label="Rol"
            label-for="role"
          >
            <b-form-select
              v-model="item.role"
              :options="roles"
              :state="item.role === '' ? false : true"
              name="user-role"
              :disabled="editable"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="3"
        >
          <b-form-group
            label="Estado"
            label-for="active"
          >
            <v-select
              v-model="item.active"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="option"
              label="title"
              :disabled="editable"
            >
              <template #option="{ title, icon }">
                <feather-icon
                  :icon="icon"
                  size="16"
                  class="align-middle mr-50"
                />
                <span> {{ title }}</span>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="3"
        >
          <b-form-group
            label="Correo"
            label-for="email"
          >
            <b-form-input
              v-model="item.email"
              name="email"
              :disabled="editable"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="3"
        >
          <b-form-group
            label="Teléfono"
            label-for="telefono"
          >
            <b-form-input
              v-model="item.telefono"
              name="telefono"
              type="number"
              :disabled="editable"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="usuario.role === 'superadmin'"
          cols="3"
        >
          <b-form-group
            label="Empresa"
            label-for="idCompany"
          >
            <b-form-select
              v-model="item.idCompany"
              :options="companies"
              :state="(item.idCompany === 0 || item.idCompany === '' || item.idCompany === null) ? false : true"
              :disabled="editable"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="3"
        >
          <b-form-group
            label="Nombre Empresa"
            label-for="empresa"
          >
            <b-form-input
              v-model="item.empresa"
              name="empresa"
              :disabled="editable"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="3"
        >
          <!--<b-form-group
            label-for="temporalPassword"
          >
            <h6>Solicitar cambio de contraseña al ingresar</h6>
            <b-form-checkbox
              v-model="item.temporalPassword"
              :checked="item.temporalPassword"
              class="custom-control-secondary"
              name="temporalPassword"
              switch
              :disabled="editable"
            />
          </b-form-group>-->
        </b-col>

        <b-col
          v-if="editable"
          cols="12"
        >
          <br>
        </b-col>
        <b-col
          v-if="editable"
          cols="12"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="editable=false"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
            />
            <span class="align-middle">Editar</span>
          </b-button>
        </b-col>
        <b-col
          v-if="!editable"
          cols="12"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="updateItem"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            <span class="align-middle">Actualizar</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="cancel"
          >
            <feather-icon
              icon="SlashIcon"
              class="mr-50"
            />
            <span class="align-middle">Cancelar</span>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  // BFormCheckbox,
  BRow,
  BCol,
  BCard,
  BFormSelect,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import { getRoles, updateCreateItem, getUsuario } from '@/utils/usuarios'
import { getCompaniesSelect } from '@/utils/companies'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    // BFormCheckbox,
    vSelect,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      catalogoName: 'Usuario',
      usuario: JSON.parse(localStorage.getItem('userData')),
      item: {},
      companies: [],
      roles: getRoles(JSON.parse(localStorage.getItem('userData'))),
      editable: router.currentRoute.params.action !== 'Edit',
      option: [
        {
          title: 'ACTIVO',
          icon: 'ToggleRightIcon',
          value: true,
        },
        {
          title: 'INACTIVO',
          icon: 'ToggleLeftIcon',
          value: false,
        },
      ],
    }
  },
  async created() {
    await this.cargarItem()
    this.companies = await getCompaniesSelect()
  },
  methods: {
    async updateItem() {
      if (this.item.active.value !== undefined) this.item.active = this.item.active.value
      delete this.item.company
      this.item.id = router.currentRoute.params.id
      this.item.telefono = this.item.telefono.toString()
      const result = await updateCreateItem(this.item, 1)
      if (result !== null) {
        this.$router.replace('/usuarios').then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `${this.catalogoName} Actualizado!`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `${this.catalogoName} actualizado correctamente!`,
            },
          })
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `${this.catalogoName} no Actualizado`,
            icon: 'FrownIcon',
            variant: 'warning',
            text: `${this.catalogoName} no Actualizado!`,
          },
        })
      }
    },
    async cancel() {
      if (router.currentRoute.params.action === 'Edit') await this.$router.replace('/usuarios')
      else await this.cargarItem()
    },
    async cargarItem() {
      try {
        const result = await getUsuario(router.currentRoute.params.id)
        if (result !== null) {
          // eslint-disable-next-line prefer-destructuring
          this.item = result
          this.item.active = this.item.active ? {
            title: 'ACTIVO',
            icon: 'ToggleRightIcon',
            value: true,
          } : {
            title: 'INACTIVO',
            icon: 'ToggleLeftIcon',
            value: false,
          }
        }
      } catch (err) {
        console.error(`Error en cargar ${this.catalogoName}`, err)
        this.items = null
      }
      this.editable = router.currentRoute.params.action !== 'Edit'
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
